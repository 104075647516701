
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import { Contact, Contacts, ContactsKeyType } from '@point/utility-classes';
import RuleTypes from '../../../types/validationRules';
import utils from '../../../util';

const { friendlyContactType } = utils;
const validationRules = utils.ValidationRules;

export default Vue.extend({
  name: 'linearAdminContacts',
  directives: { mask },
  props: ['editRights'],
  data: (): {
    fadeSave: boolean;
    validationRules: RuleTypes;
    valid: boolean;
    scopedContacts: Contacts | null;
    keys: ContactsKeyType[];
  } => ({
    fadeSave: true,
    validationRules,
    valid: false,
    scopedContacts: null,
    keys: [],
  }),
  created() {
    this.scopedContacts = JSON.parse(JSON.stringify(this.contacts));
    this.keys = Object.keys(this.$store.state?.advertiser?.contacts);
    Vue.nextTick(() => {
      this.fadeSave = true;
    });
  },
  computed: {
    loading(): boolean {
      return this.$store.state?.advertiser?.isContactsLoading || this.$store.state?.advertiser?.isUpdateContactsLoading;
    },
    saveButtonStyle(): object | null {
      if (this.fadeSave) return { opacity: 0.5 };
      return null;
    },
    advertiserInfo(): object {
      return this.$store.state.advertiser?.advertiserInfo?.data || null;
    },
    contacts(): Contacts {
      return this.$store.state?.advertiser?.contacts || null;
    },
  },
  methods: {
    friendlyContactType(type: ContactsKeyType): string {
      return friendlyContactType(type, this.advertiserInfo?.AgencyPartnerName);
    },
    copyDecisionMaker(contact: Contact, type: ContactsKeyType): void {
      const selected = contact.is_copy_of_dm;
      const objectToUpdate = this.scopedContacts[type];

      for (const value in objectToUpdate) {
        objectToUpdate[value] = '';
        if (value === 'is_copy_of_dm') {
          objectToUpdate[value] = selected;
        }
      }
    },
  },
  watch: {
    contacts(contacts: Contacts) {
      if (!this.loading) {
        // watches the store object for changes
        this.scopedContacts = contacts;
        Vue.nextTick(() => {
          this.fadeSave = true;
        });
      }
    },
    scopedContacts: {
      // watches the locally scoped contacts for changes
      deep: true,
      handler() {
        this.fadeSave = false;
      },
    },
  },
});
